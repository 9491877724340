<template>
  <div>
    <SubNav title="Saved Data" />
    <div class="container-fluid pb-1">
      <div class="mb-sm-4 mx-3 my-4 row">
        <div class="col-lg-12">
          <div class="border">
            <!--  -->
            <div class="row p-3">
              <div class="col-lg-3 mb-1">
                <span>{{ tableDescription }}</span>
              </div>
              <div class="col-lg-6">
                <b-form @submit.prevent="performSearch">
                  <b-form-input
                    v-model="search"
                    title="Press Enter to search ..."
                    :placeholder="ps"
                    type="search"
                    @keyup.enter="performSearch"
                  ></b-form-input>
                </b-form>
              </div>
              <div class="col-lg-3">
                <button
                  title="Delete Selected records"
                  class="btn mr-2 float-right btn-action btn-warning"
                >
                  Delete
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </button>
                <button
                  title="Refresh list"
                  class="btn mr-2 float-right btn-action btn-secondary"
                  @click="loadRecords"
                >
                  <b-icon icon="arrow-clockwise" aria-hidden="true"></b-icon>
                </button>
              </div>
            </div>

            <!--  -->
            <TableComponent
              :fields="fields"
              bordered
              :data="preview"
              :loading="tableLoading"
              :empty-text="emptyText"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              @sizeChange="handlePageSizeChange"
              @selected="handleSelection"
              @sort:by="(by) => (sortBy = by)"
              @sort:direction="(isDescending) => (sortDesc = isDescending)"
            >
              <template #cell="{ row }">
                <span v-if="row.field.key === 'name'">
                  <svg-icon icon-class="excel" />
                  <span style="padding-left: 5px">{{ row.value }}</span>
                </span>
                <span v-else>{{ row.value }}</span>
              </template>
            </TableComponent>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubNav from "../../components/NavigationSubNav.vue";
import DataMixin from "./mixin";
import { mapGetters } from "vuex";
import TableComponent from "../../components/Table.vue";
import { DateTime } from "luxon";
import lunar from "lunr";
import { random } from "../../utils";

const formatter = (value) => {
  return new DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
};

const searchPlaceHolders = [
  "name:NHMIS 2017 Report (.xls)",
  "name:NHMIS* ",
  "created_at:2021*",
  "status:uploaded",
  "name:NHMIS* status:uploaded",
  "+status:uploaded +created_at:2021*",
];

const searchFields = ["name", "status", "created_at", "owner"];

const normalizeDate = function (builder) {
  var pipelineFunction = function (token) {
    return token;
  };
  // Register the pipeline function so the index can be serialized
  lunar.Pipeline.registerFunction(pipelineFunction, "normalizeDate");
  // Add the pipeline function to both the indexing pipeline and the
  // searching pipeline
  builder.pipeline.before(lunar.stemmer, pipelineFunction);
  builder.searchPipeline.before(lunar.stemmer, pipelineFunction);
};

export default {
  name: "SavedData",
  components: {
    SubNav,
    TableComponent,
  },
  mixins: [DataMixin],

  data() {
    return {
      sortBy: "created_at",
      sortDesc: true,
      fetchInterval: undefined,
      search: undefined,
      ps: searchPlaceHolders[random(0, searchPlaceHolders.length - 1)],
    };
  },

  computed: {
    ...mapGetters({ records: "data/uploads" }),
    fields() {
      return [
        { key: "name", sortable: true },
        { key: "status", sortable: true },
        {
          key: "created_at",
          label: "Created",
          sortable: true,
          formatter,
        },
        { key: "owner", sortable: true },
      ];
    },
    preview() {
      if (this.search && window.searchIndex) {
        try {
          const results = window.searchIndex.search(this.search);
          const records = results.map((x) => {
            return this.records.find((y) => y.id == parseInt(x.ref, 10));
          });
          return records;
        } catch (error) {
          console.log(error);
        }
      }
      return this.records;
    },
    tableDescription() {
      const amount =
        this.perPageSize > this.records.length ? this.records.length : this.perPageSize;
      return `Showing ${amount} of ${this.records.length} records`;
    },
  },
  beforeDestroy() {
    if (this.fetchInterval) clearInterval(this.fetchInterval);
  },
  mounted() {
    this.fetchInterval = setInterval(() => {
      this.loadRecords();
    }, 600000);
  },
  created() {
    if (!this.records.length) {
      return this.loadRecords();
    }
    this.buildSearchIndex(JSON.parse(JSON.stringify(this.records)));
  },
  methods: {
    // TODO method to perform search over the api
    performSearch() {},

    /**
     * High performant search algorithm, performs a local search on records
     * present in the store
     *
     * @see https://lunrjs.com/
     */
    async buildSearchIndex(records) {
      records = records.map((x) => {
        x.created_at = formatter(x.created_at);
        return x;
      });

      const index = lunar(function () {
        this.use(normalizeDate);
        searchFields.forEach(function (field) {
          this.field(field);
        }, this);

        this.ref("id");

        records.forEach(function (doc) {
          this.add(doc);
        }, this);
      });

      // Store on window to avoid memory leak on large datasets
      window.searchIndex = index;
    },

    loadRecords() {
      if (this.tableLoading) return;
      this.tableLoading = true;
      this.$store
        .dispatch("data/fetchUploads")
        .catch((error) => {
          const statusCode = error?.response?.status || 500;
          if (403 == statusCode) {
            // @dev render test data
            this.$store.commit("data/SET_UPLOADS", this.generateTestData());
            this.$notify({
              position: "top-right",
              message: "Error fetching uploads. StatusCode::403. Using test data",
              duration: 0,
              type: "warning",
            });
          }
        })
        .finally(() => {
          this.tableLoading = false;
          this.buildSearchIndex(JSON.parse(JSON.stringify(this.records)));
        });
    },

    generateTestData() {
      return new Array(50).fill(0).map((_, index) => {
        let x = {};
        x.id = index;

        x.name = "NHMIS 2017 Report (.xls)";
        x.status = "uploaded";
        x.created_at = DateTime.now().minus({ days: random(3, 32) });
        x.owner = "";
        return x;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: green;
}
input::placeholder {
  color: #dae2e9;
  opacity: 1;
}
input:-ms-input-placeholder {
  color: #dae2e9;
  opacity: 1;
}

input::-ms-input-placeholder {
  color: #dae2e9;
  opacity: 1;
}
</style>
